<template>
    <section class="fields-container">
        <ClientField
            v-for="(value, key) in clientSelectedFiltered"
            :key="key"
            :field="key"
            :value="value"
            :disabled="isShadow || isReadOnly"
            :client="currentSelectedClient"
        />
    </section>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';

    import ClientField from './ClientField.vue';
    import { clientFieldOrder } from '@/enums/client.enums';

    export default {
        components: { ClientField },

        computed: {
            ...mapState({
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
            }),

            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
            }),

            isShadow() {
                // If the client type is we are looking at is a shadow client disable fields
                return this.currentSelectedClient.type === 'shadow';
            },

            clientSelectedFiltered() {
                const { currentSelectedClient, clientVisibleFields } = this;

                // Filter and order the client data
                const currentSelectedClientFiltered = {};

                for (const key of clientFieldOrder) {
                    if (clientVisibleFields.includes(key) && key in currentSelectedClient) {
                        currentSelectedClientFiltered[key] = currentSelectedClient[key];
                    }
                }

                return currentSelectedClientFiltered;
            },

            clientVisibleFields() {
                return this.clientNowOnCard.visibleFields;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .fields-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        grid-gap: 16px;
        padding: 16px;
        font-size: 12px;
        color: var(--v-gray1-base);
    }
</style>
