<template>
    <main class="client-contact-card-container">
        <section class="client-contact-container">
            <ClientFamily :clientId="clientId" />

            <section v-if="isShadow" class="multiple-client-match-container">
                <button class="multiple-client-match" @click="mergeClient">
                    {{ $t('clientContactInfo.clientMatch') }}
                </button>
            </section>
        </section>

        <section class="action-container">
            <ClientChildren @select-item="handleClientAction" />
            <div class="button-container">
                <standard-select
                    :dense="true"
                    single
                    :items="actionButtons"
                    :disabled="isReadOnly"
                    :resetOnClick="true"
                    @change="(item) => handleAction(item[0])"
                >
                    <template #item-slot="{ item }">
                        <section class="action-item">
                            <div>
                                <i class="icon mdi" :class="[item.icon]" :style="{ color: item.color }"></i>
                            </div>
                            <div class="item-text">
                                <span>{{ $t(item.textKey) }}</span>
                            </div>

                            <div>
                                <v-tooltip right>
                                    <template #activator="{ on, attrs }">
                                        <i class="icon mdi mdi-help info-icon" v-bind="attrs" v-on="on"></i>
                                    </template>
                                    <span>{{ $t(item.textKey + 'Tooltip') }}</span>
                                </v-tooltip>
                            </div>
                        </section>
                    </template>
                </standard-select>
            </div>
            <div>
                <BankIdButton
                    :key="currentSelectedClient?.clientId + '_bankId'"
                    :caseId="caseToLoad"
                    :clientId="currentSelectedClient?.clientId"
                    :ssn="currentSelectedClient?.socialSecurityNumber"
                    :disabled="isReadOnly"
                />
            </div>
        </section>
    </main>
</template>
<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import BankIdButton from './BankIdButton.vue';
    import ClientFamily from './ClientCard/ClientFamily.vue';
    import ClientChildren from './ClientCard/ClientChildren.vue';

    export default {
        components: {
            BankIdButton,
            ClientFamily,
            ClientChildren,
        },

        data() {
            return {
                // View
                isExpanded: false,

                // Modals
                clientAssignModal: false,
                caseAssignModal: false,
            };
        },

        computed: {
            ...mapState({
                caseToLoad: (state) => state.Cases.caseToLoad,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
                selectedCase: (state) => state.Cases.selectedCase,
                actionButtonsClient: (state) => state.Client.actionButtonsClient,
                actionButtonsShadow: (state) => state.Client.actionButtonsShadow,
                actionButtonsContact: (state) => state.Client.actionButtonsContact,
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                readClientCard: (state) => state.Layout.readClientCard,
                readStateClientCard: (state) => state.Layout.readStateClientCard,
                clientId: (state) => state.Cases.selectedCase.case.clientId,
            }),

            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
                selectedClientId: 'Client/selectedClientId',
            }),

            actionButtons() {
                if (this.isShadow) {
                    return this.actionButtonsShadow;
                }

                if (this.currentSelectedClient?.type === 'contact') {
                    return this.actionButtonsContact;
                }

                return this.actionButtonsClient;
            },

            isShadow() {
                return this.currentSelectedClient?.type === 'shadow';
            },

            mappedClientContacts() {
                return [...this.clientContacts].map((contact) => ({
                    text: contact.data.name,
                    value: contact.data.clientId,
                    icon: this.clientIcon(contact.data.type),
                    type: contact.data.type,
                }));
            },

            clientContacts() {
                return this.clientNowOnCard.contacts;
            },

            clientSelectedMapped() {
                return {
                    text: this.currentSelectedClient.data.name,
                    value: this.currentSelectedClient.data.clientId,
                    icon: this.clientIcon(this.currentSelectedClient.data.type),
                    type: this.currentSelectedClient.data.type,
                };
            },
        },

        watch: {
            clientNowOnCard: {
                handler(newValue) {
                    this.getClient(newValue.data.clientId);
                },
                deep: true,
                immediate: true,
            },
        },

        beforeDestroy() {
            this.unsubscribeToClient(this.currentSelectedClient);
        },

        methods: {
            ...mapActions('Cases', ['setClientContactInfo', 'setClientOnCard']),

            ...mapActions('Client', [
                'handleAction',
                'updateClient',
                'setClientLoading',
                'setCurrentSelectedClient',
                'subscribeToClient',
                'unsubscribeToClient',
                'getClient',
            ]),

            mergeClient() {
                this.handleAction({ value: 'mergeClient' });
            },

            clientIcon(type) {
                switch (type) {
                    case 'client': {
                        return 'mdi-link';
                    }
                    case 'contact': {
                        return 'mdi-account';
                    }
                    case 'shadow': {
                        return 'mdi-account-question';
                    }
                    default: {
                        return 'mdi-link';
                    }
                }
            },

            handleClientAction(item) {
                this.getClient(item.id);
            },

            expand() {
                this.isExpanded = !this.isExpanded;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .item-text {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
    }

    .client-parent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: none !important;
    }

    .client-parent-hover {
        cursor: pointer;
    }

    .client-contact-card-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .action-container {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: 16px;
        padding: 16px;
    }

    .action-container > * {
        justify-self: end;
    }

    .client-contact-container {
        display: grid;
        grid-template-columns: 1fr;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .client-contact-container > * {
        align-self: center;
    }

    .multiple-client-match {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        color: var(--v-error-base);
        font-size: 12px;
        padding: 16px;
        border: 1px solid var(--v-error-lighten5);
        border-radius: 6px;
        transition: background-color 0.1s ease-in-out;
        flex: 1;
    }

    .multiple-client-match:hover {
        background-color: var(--v-gray5-base);
        border: 1px solid rgb(0, 0, 0, 0.15);
        color: var(--v-gray4-base);
    }

    .multiple-client-match-container {
        display: flex;
        align-items: center;
        justify-content: stretch;
        padding: 16px;
    }

    .button-container {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        flex: 1;
        justify-self: stretch;
    }

    .action-item {
        display: grid;
        grid-template-columns: 48px 1fr 24px;
        width: 100%;
    }

    .action-item > :first-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .action-item > * {
        align-self: center;
    }

    .action-item > :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-icon {
        color: var(--v-gray2-base);
        font-size: 12px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientContactInfo": {
            "clientMatch": "Client matched against multiple clients in the system"
        }
    },
    "sv": {
        "clientContactInfo": {
            "clientMatch": "Klient matchad mot flera klienter i systemet"
        }
    }
}
</i18n>
