import axios from 'axios';

function updateClient(payload) {
    // * extract the clientId from the payload
    const { clientId, ...data } = payload;
    return axios.put(`/client/${clientId}`, data);
}

function updateClientFields(payload) {
    const { clientId, ...data } = payload;
    return axios.patch(`/client/${clientId}/fields`, data);
}

function createClient(payload) {
    return axios.post('/client', payload);
}

function getClient(id) {
    return axios.get(`/client/${id}`);
}

function getClientV2(id) {
    return axios.get(`/v2/clients/${id}`);
}

function getClientByCaseId(id) {
    return axios.get(`/client/case/${id}`);
}

function deleteClient(id) {
    return axios.delete(`/client/${id}`);
}

function getClients(params) {
    return axios.get('/clients', { params });
}

function getAddresses(payload) {
    return axios.get(`/client/address/${payload.search}`);
}

function getCustomerTypes(payload) {
    return axios.get('/clients/fields/customerTypes', { params: payload });
}

function createCustomerType(name) {
    return axios.post('/clients/fields/customerTypes', { name });
}

function getClientsFamily(id, limit, offset) {
    return axios.get(`/clients/${id}/parents`, { params: { limit, offset } });
}

function getClientsChildren(id, limit, offset) {
    return axios.get(`/clients/${id}/children`, { params: { limit, offset } });
}

function getTimeline(id, limit, offset) {
    return axios.get(`/clients/${id}/timeline`, { params: { limit, offset } });
}

export default {
    // Basic
    updateClient,
    updateClientFields,
    createClient,
    getClient,
    deleteClient,

    // Case specific
    getClientByCaseId,

    // Paginated search
    getClients,

    // Get addresses
    getAddresses,

    getCustomerTypes,
    createCustomerType,

    getClientsFamily,
    getClientsChildren,

    getClientV2,
    getTimeline,
};
