export const tiptapStates = Object.freeze({
    CLOSED: 'closed',
    OPEN: 'open',
    FULL: 'full',
});

export const contentTypes = Object.freeze({
    TEXT: 'text',
    HTML: 'html',
});
