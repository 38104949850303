module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "infiniteMenu": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
        }
      },
      "sv": {
        "infiniteMenu": {
          "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"infiniteMenu":{"searchPlaceholder":"Search"}},"sv":{"infiniteMenu":{"searchPlaceholder":"Sök"}}}')
  delete Component.options._Ctor
  
}
