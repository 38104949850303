import cases from '@/api/cases/cases';
import i18n from '../../i18n';

const filterStates = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
};

const createCaseStates = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
};

import { searchStates } from '@/enums/header.enums';

let debouncer = null;

const state = {
    // Filter states
    filter: filterStates.CLOSED,
    components: [
        {
            textKey: 'sideMenu.filterCases',
            routes: ['Cases', 'Case'],
            component: 'CasesFilterMenu',
            state: filterStates.CLOSED,
            closing: {
                autoClose: {
                    active: false,
                },
                clickOutside: {
                    active: false,
                },
            },
        },
        {
            textKey: 'leaderboard.leaderboardSettings',
            routes: ['GlobalLeaderboard', 'IndividualLeaderboard'],
            component: 'LeaderboardFilterMenu',
            state: filterStates.CLOSED,
            closing: {
                autoClose: {
                    active: true,
                    seconds: 20,
                },
                clickOutside: {
                    active: true,
                },
            },
        },
    ],
    // Search states
    search: JSON.parse(localStorage.getItem('search')) || searchStates.ALL,

    searchTypes: [
        {
            textKey: 'search.all',
            type: 'all',
            icon: 'mdi-magnify',
            value: searchStates.ALL,
            color: 'var(--v-gray2-base)',
        },
        {
            textKey: 'search.onlyFilter',
            type: 'onlyFilter',
            icon: 'mdi-filter',
            value: searchStates.ONLY_FILTER,
            color: 'var(--v-gray2-base)',
        },
        {
            textKey: 'search.onlyCommentsAndFilter',
            type: 'onlyCommentsAndFilter',
            icon: 'mdi-comment-search',
            value: searchStates.ONLY_COMMENTS_AND_FILTER,
            color: 'var(--v-gray2-base)',
        },
        {
            textKey: 'search.onlyComments',
            type: 'onlyComments',
            icon: 'mdi-comment-search-outline',
            value: searchStates.ONLY_COMMENTS,
            color: 'var(--v-gray2-base)',
        },
    ],

    currentSearch: JSON.parse(localStorage.getItem('currentSearch')) || {
        textKey: 'search.all',
        type: 'all',
        icon: 'mdi-magnify',
        value: searchStates.ALL,
        color: 'var(--v-gray2-base)',
    },
    dialogTypes: [
        {
            textKey: 'header.createCase',
            type: 'createCase',
            icon: 'mdi-folder-plus',
            value: searchStates.ALL,
            color: 'var(--v-gray2-base)',
            component: 'CreateCase',
        },
        {
            textKey: 'header.createCommunication',
            type: 'createCommunication',
            icon: 'mdi-comment-plus',
            value: searchStates.ONLY_FILTER,
            color: 'var(--v-gray2-base)',
            component: 'CreateCommunication',
        },
        {
            textKey: 'header.createQuickCustomer',
            type: 'createQuickCustomer',
            icon: 'mdi-account-plus',
            value: searchStates.ONLY_COMMENTS_AND_FILTER,
            color: 'var(--v-gray2-base)',
            component: 'CreateQuickCustomer',
        },
    ],
};
// Getter functions
const getters = {
    sideMenuOpen(state) {
        return state.filter === filterStates.OPEN;
    },
};
// Actions
const actions = {
    toggleFilters({ commit, state }) {
        clearTimeout(debouncer);
        debouncer = setTimeout(() => {
            commit('SHOW_FILTERS', state.filter === filterStates.CLOSED);
        }, 1);
    },
    showFilters({ commit }, payload) {
        commit('SHOW_FILTERS', payload);
    },
    setSearchState({ commit }, payload) {
        commit('SET_SEARCH_STATE', payload);
    },
    setCreateCase({ commit }, payload) {
        commit('SET_CREATE_CASE_STATE', payload);
    },
    toggleCreateCase({ commit, state }) {
        // If the createCase is open, close it
        if (state.createCase === createCaseStates.OPEN) {
            commit('SET_CREATE_CASE_STATE', false);
            return;
        }
        commit('SET_CREATE_CASE_STATE', true);
    },
};
// Mutations
const mutations = {
    SHOW_FILTERS(state, payload) {
        if (payload) {
            state.filter = filterStates.OPEN;
        } else {
            state.filter = filterStates.CLOSED;
        }
    },

    SET_SEARCH_STATE(state, payload) {
        state.search = payload.at(0).value;
        state.currentSearch = state.searchTypes.find((search) => search.value === payload.at(0).value);

        if (state.search !== undefined && state.search !== null) {
            localStorage.setItem('search', JSON.stringify(state.search));
        } else {
            localStorage.removeItem('search');
        }

        if (state.currentSearch !== undefined && state.currentSearch !== null) {
            localStorage.setItem('currentSearch', JSON.stringify(state.currentSearch));
        } else {
            localStorage.removeItem('currentSearch');
        }
    },

    SET_CREATE_CASE_STATE(state, payload) {
        if (payload) {
            state.createCase = createCaseStates.OPEN;
        } else {
            state.createCase = createCaseStates.CLOSED;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
