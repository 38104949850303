import axios from 'axios';

function getFavouriteEmails(queueId, limit, offset) {
    return axios.get(`/favourites/${queueId}/emails?limit=${limit}&offset=${offset}`);
}

function addFavouriteEmail(queueId, email) {
    return axios.post(`/favourites/${queueId}/emails`, { email });
}

function removeFavouriteEmail(queueId, email) {
    return axios.delete(`/favourites/${queueId}/emails/${email}`);
}

function getFrequentEmails(queueId, limit, offset) {
    return axios.get(`/favourites/${queueId}/frequent-emails?limit=${limit}&offset=${offset}`);
}

function getCombinedEmails(queueId, limit, offset, search) {
    return axios.get(
        `/favourites/${queueId}/combined-emails?limit=${limit}&offset=${offset}&search=${encodeURIComponent(search)}`,
    );
}

function getQueueEmails(queueId, limit, offset, search) {
    return axios.get(
        `/favourites/${queueId}/queue-emails?limit=${limit}&offset=${offset}&search=${encodeURIComponent(search)}`,
    );
}

export default {
    getFavouriteEmails,
    addFavouriteEmail,
    removeFavouriteEmail,
    getFrequentEmails,
    getCombinedEmails,
    getQueueEmails,
};
