<template>
    <section>
        <v-menu v-model="menuOpen" offset-y>
            <template #activator="{}">
                <div @click="menuOpen = !menuOpen"><slot></slot></div>
            </template>
            <section>
                <section :key="menuOpen" class="list-container">
                    <infinite-list
                        :headers="headers"
                        :fetchItems="fetchFunction"
                        :fetchProps="fetchProps"
                        :items="items"
                        :limit="limit"
                        :search="search"
                        :emptyHeader="emptyHeader"
                        :emptyMessage="emptyMessage"
                        :emptyIcon="emptyIcon"
                    >
                        <template #item="{ item }">
                            <div @click.prevent.stop="clickItem(item)">
                                <slot name="item" :item="item"></slot>
                            </div>
                        </template>
                    </infinite-list>
                </section>

                <section v-if="isSearchable" @click.prevent.stop>
                    <search-input
                        v-model="search"
                        input-radius
                        :placeholder="$t('infiniteMenu.searchPlaceholder')"
                        @click.prevent.stop
                    />
                </section>

                <section @click.stop>
                    <slot name="footer"></slot>
                </section>
            </section>
        </v-menu>
    </section>
</template>

<script>
    export default {
        name: 'InfniteMenu',

        props: {
            isSearchable: {
                type: Boolean,
                default: true,
            },

            headers: {
                type: Array,
                default: () => [],
            },

            fetchFunction: {
                type: Function,
                default: () => {},
            },

            fetchProps: {
                type: Object,
                default: () => ({}),
            },

            items: {
                type: Array,
                default: () => [],
            },

            limit: {
                type: Number,
                default: 30,
            },

            emptyHeader: {
                type: String,
                default: '',
            },

            emptyMessage: {
                type: String,
                default: '',
            },

            emptyIcon: {
                type: String,
                default: '',
            },

            closeMenuOnClick: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                menuOpen: false,
                search: '',
            };
        },

        watch: {
            menuOpen(val) {
                if (!val) {
                    this.search = '';
                }
            },
        },

        methods: {
            clickItem(item) {
                this.$emit('click-item', item);
                if (this.closeMenuOnClick) {
                    this.menuOpen = false;
                }
            },
        },
    };
</script>

<style scoped>
    .list-container {
        min-height: 300px;
        max-height: 300px;
        display: flex;
        justify-content: stretch;
        flex-direction: column;
        overflow: hidden;
        border-radius: 8px;
    }

    div[role='menu'] {
        border-radius: 8px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
        border: 1px solid #eee;
        padding: 4px;
        background-color: white;
        min-width: 240px !important;
        max-width: 240px !important;
    }

    :deep(.v-input__prepend-inner) {
        align-self: center;
        margin: 0px !important;
        padding: 0px !important;
        white-space: nowrap !important;
    }
</style>

<i18n>
    {
        "en": {
            "infiniteMenu": {
                "searchPlaceholder": "Search"       
            }
        },
    
        "sv": {
            "infiniteMenu": {
                "searchPlaceholder": "Sök"    
            }
        }
    }
</i18n>
