<template>
    <main class="video-card" :style="videoCardStyle">
        <div v-if="!isActive">{{ $t('videoModule.integrationNotActive') }}</div>
        <div v-else-if="currentState === meetingStates.MEETING_ERROR">
            <v-card-text>
                {{ $t('videoModule.error') }}
            </v-card-text>
        </div>
        <div v-else-if="currentState === meetingStates.INITIATED" class="initiated-meeting-container">
            <main>
                <section class="video-description">
                    <div class="caption">{{ $t('videoModule.startMeet') }}</div>
                    <div class="box">
                        {{ $t('videoModule.commenceConference') }}
                        <br />
                        {{ $t('videoModule.shareLink') }}
                    </div>
                </section>

                <div class="video-switches">
                    <v-switch
                        v-model="sendText"
                        class="video-switch"
                        :disabled="!validClientNumber"
                        color="primary"
                        :label="smsLabel"
                    />
                    <v-switch
                        v-model="sendMail"
                        class="video-switch"
                        :disabled="!validClientMail"
                        color="primary"
                        :label="mailLabel"
                    />
                </div>
            </main>

            <footer class="video-action-container">
                <v-btn :disabled="!(sendText || sendMail)" color="primary" depressed @click="startVideoMeeting">
                    {{ $t('videoModule.start') }}
                </v-btn>
            </footer>
        </div>
        <div v-else-if="currentState === meetingStates.IN_MEETING" class="in-meeting-container">
            <iframe
                frameborder="0"
                allowfullscreen
                width="100%"
                height="100%"
                :src="link"
                allow="camera;microphone;fullscreen;display-capture;"
            ></iframe>
            <footer>
                <div class="text-caption">{{ $t('videoModule.theirLink') }}</div>
                <v-text-field
                    hide-details
                    background-color="gray3"
                    class="input-field text-truncate"
                    dense
                    outlined
                    :value="linkToShare"
                >
                    <template #append>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn height="24" width="24" icon v-on="on" @click="copyText(linkToShare)">
                                    <v-icon size="16" color="var(--v-gray2-base)">mdi-content-copy</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('videoModule.copyLink') }}</span>
                        </v-tooltip>
                    </template>
                </v-text-field>
            </footer>

            <div class="video-footer">
                <div class="video-actions">
                    <v-btn depressed color="error" class="ml-2" @click="currentState = meetingStates.MEETING_COMPLETED">
                        {{ $t('videoModule.endMeeting') }}
                    </v-btn>
                </div>
            </div>
        </div>
        <div v-else-if="currentState === meetingStates.SETUP">
            <v-card-text class="d-flex justify-center">
                <v-progress-circular size="50" indeterminate color="primary" />
            </v-card-text>
        </div>
        <div v-else-if="currentState === meetingStates.MEETING_COMPLETED" class="completed-meeting-container">
            <div>{{ $t('videoModule.meetingFinished') }}</div>
            <v-icon size="160" color="primary">mdi-check</v-icon>
        </div>
    </main>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { replaceTemplateValues, mailRegEx } from '@/utils';
    import { validatePhoneNumber, formatPhoneNumber } from '@/utils/pbx.utils';

    export default {
        props: {
            start: Boolean,
        },
        data() {
            return {
                link: '',
                linkToShare: '',
                sendText: false,
                sendMail: false,
                from: null,
            };
        },
        computed: {
            ...mapGetters({
                isActiveGetter: 'Integrations.handler/isIntegrationActive',
            }),
            isActive() {
                return this.isActiveGetter('COMPODIUM');
            },
            currentState: {
                get() {
                    return this.$store.state.Meetings.currentMeetingState;
                },
                set(value) {
                    this.$store.dispatch('Meetings/setVideoMeetingState', value);
                },
            },
            loggedInUser() {
                return this.$store.state.Auth.userObject || null;
            },
            ...mapState({
                dialogState: (state) => state.Cases.toggleCommunication,
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                selectedSignature: (state) => state.Cases.selectedCaseSignature,
                selectedCase: (state) => state.Cases.selectedCase,
                companyEmails: (state) => state.Cases.systemEmails,
                meetingStates: (state) => state.Meetings.meetingModuleStates,
            }),
            validClientNumber() {
                const potentialNumbers = [
                    this.currentSelectedClient?.primaryTelephone,
                    this.currentSelectedClient?.secondaryTelephone,
                ];

                const number = potentialNumbers.find(
                    (potentialNumber) =>
                        potentialNumber != null && potentialNumber !== '' && validatePhoneNumber(potentialNumber, false)
                );

                if (number) {
                    return formatPhoneNumber(number);
                }

                return null;
            },
            validClientMail() {
                const potentialEmails = [
                    this.currentSelectedClient?.primaryEmail,
                    this.currentSelectedClient?.secondaryEmail,
                ];
                const email = potentialEmails.find(
                    (potentialEmail) =>
                        potentialEmail != null && potentialEmail !== '' && mailRegEx.test(potentialEmail)
                );

                if (email) {
                    return email;
                }

                return null;
            },
            smsLabel() {
                return this.validClientNumber ? this.$t('videoModule.sendSms') : this.$t('videoModule.noNumber');
            },
            mailLabel() {
                return this.validClientMail ? this.$t('videoModule.sendMail') : this.$t('videoModule.noMail');
            },
            getSignature() {
                return replaceTemplateValues(this.selectedSignature.Content, {
                    client: this.clientNowOnCard?.data || null,
                    agent: this.loggedInUser,
                    sender: this.fromEmail,
                });
            },
            videoCardStyle() {
                switch (this.currentState) {
                    case this.meetingStates.INITIATED:
                    case this.meetingStates.MEETING_ERROR:
                    case this.meetingStates.MEETING_COMPLETED: {
                        return {
                            height: '260px',
                        };
                    }
                    case this.meetingStates.IN_MEETING: {
                        return {
                            height: '80vh',
                        };
                    }

                    default: {
                        return {
                            height: '0px',
                        };
                    }
                }
            },
        },

        watch: {
            start: {
                handler(val) {
                    if (this.currentState === this.meetingStates.IN_MEETING) return;

                    if (val) {
                        this.currentState = this.meetingStates.INITIATED;
                    }
                },
                immediate: true,
            },
        },
        beforeDestroy() {
            this.currentState = this.meetingStates.INITIATED;
        },

        methods: {
            ...mapActions({
                startVideoMeeting: 'Cases/startVideoMeeting',
                sendSMSStore: 'VideoMeeting/sendSMS',
                sendEmailStore: 'VideoMeeting/sendEmail',
            }),
            async initMeeting() {
                try {
                    const guests = this.setGuests(this.currentSelectedClient);

                    const meetingResult = await this.$store.dispatch('Cases/startVideoMeeting', {
                        guests,
                        host: { name: this.loggedInUser.userName },
                    });

                    // link to share is the guest link
                    this.linkToShare = this.getGuestLink(meetingResult);

                    // our link is the host link
                    this.link = this.getHostLink(meetingResult);

                    this.currentState = this.meetingStates.IN_MEETING;
                    this.sendVideoMeeting();
                } catch (error) {
                    console.error(error);
                    this.currentState = this.meetingStates.MEETING_ERROR;
                }
            },
            setGuests(guest) {
                return [
                    {
                        name: guest?.name || guest?.primaryEmail || guest?.secondaryEmail || 'Guest',
                        role: 'guest',
                    },
                ];
            },
            getGuestLink(meetingResult) {
                const guest = meetingResult.find(
                    (x) =>
                        (x.role === 'guest' && x.name === this.currentSelectedClient?.name) ||
                        x.name === this.currentSelectedClient?.primaryEmail ||
                        x.name === this.currentSelectedClient?.secondaryEmail
                );
                return guest ? guest.urlLink : '';
            },
            getHostLink(meetingResult) {
                const host = meetingResult.find((x) => x.role === 'admin');
                return host ? host.urlLink : '';
            },
            startVideoMeeting() {
                this.initMeeting();
            },

            sendVideoMeeting() {
                if (this.currentState !== this.meetingStates.IN_MEETING) return;

                if (this.sendText) {
                    this.sendSMS();
                }
                if (this.sendMail) {
                    this.sendEmail();
                }
            },
            async sendSMS() {
                const payload = {
                    close: false,
                    comment: this.linkToShare,
                    caseId: this.$route.params.caseId,
                    ext: true,
                    from: this.loggedInUser.userName,
                    userId: this.loggedInUser.userId,
                    reciever: this.validClientNumber,
                    subject: this.$t('videoModule.videoMeeting'),
                    recieverNumber: this.validClientNumber,
                    attachment: [{ name: '' }],
                    messageType: 'sms',
                    msgId: 0,
                };
                await this.sendSMSStore(payload);
            },
            async sendEmail() {
                const payload = {
                    comment: `<a href="${this.linkToShare}">${this.$t('videoModule.joinMeeting')}</a>`,
                    caseId: this.$route.params.caseId,
                    ext: true,
                    sender: this.loggedInUser.userName,
                    from: this.loggedInUser.Email,
                    userId: this.loggedInUser.userId,
                    reciever: this.validClientMail,
                    subject: this.$t('videoModule.videoMeeting'),
                    attachment: [{ name: '' }],
                    typeOfMessage: 'mail',
                    msgId: 1,
                    close: false, // close case true or false
                    signature: this.getSignature,
                };

                await this.sendEmailStore(payload);
            },

            copyText(text) {
                // add text to clipboard
                navigator.clipboard.writeText(text);
                // show toast
                this.$toasted.show(this.$t('videoModule.copied'), {
                    icon: 'mdi-content-copy',
                    type: 'success',
                    duration: 3000,
                });
            },
            replaceTemplateValues,
        },
    };
</script>

<style lang="scss" scoped>
    .video-action-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
    }
    .input-field {
        display: flex;
        flex: 1;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .video-card {
        padding: 24px 0;
        background-color: white;
        width: 90vw;
        max-width: 1000px;
        transition: height 0.5s ease;
        display: flex;
    }
    .video-switch {
        padding-right: 16px;
    }
    .video-switches {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }

    .video-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
    }

    .video-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }

    .video-description {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .video-description .caption {
        font-size: 16px;
        font-weight: 500;
    }

    .video-description .box {
        padding: 8px;
        width: 100%;
        border-radius: 6px;
        border: 2px solid var(--v-primary-base);
    }

    .in-meeting-container {
        flex: 1;
        display: grid;
        grid-template-rows: 1fr 90px;
    }
    .in-meeting-container footer {
        padding-top: 16px;
    }

    .initiated-meeting-container {
        display: grid;
        grid-template-rows: 1fr 36px;
        flex: 1;
    }

    .completed-meeting-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-size: 16px;
    }
</style>

<i18n lang="json">
{
    "en": {
        "videoModule": {
            "startMeet": "Start a video meeting",
            "joinMeeting": "Join the meeting",
            "error": "Something went wrong when starting the meeting, try again. If the problem persists, contact support.",
            "meetingFinished": "The meeting is finished",
            "theirLink": "Their link",
            "commenceConference": "Commence an immediate video conference.",
            "shareLink": "The link will be sent by SMS or and email after you start the meeting.",
            "start": "Start",
            "endMeeting": "End meeting",
            "sendSms": "Send SMS",
            "sendMail": "Send mail",
            "noNumber": "No number found",
            "noMail": "No mail found",
            "copyLink": "Copy link",
            "copied": "Copied to clipboard",
            "sendMeeting": "Send meeting",
            "videoMeeting": "Video meeting",
            "integrationNotActive": "The integration is not active"
        }
    },
    "sv": {
        "videoModule": {
            "startMeet": "Starta ett videomöte",
            "joinMeeting": "Gå med i mötet",
            "error": "Något gick fel vid start av mötet, testa igen. Om problemet kvarstår kontakta support.",
            "meetingFinished": "Mötet är avslutat",
            "theirLink": "Deras länk",
            "commenceConference": "Inled en omedelbar videokonferens.",
            "shareLink": "Länken skickas via SMS eller E-post efter att du startat mötet.",
            "start": "Börja",
            "endMeeting": "Avsluta möte",
            "sendSms": "Skicka SMS",
            "sendMail": "Skicka E-post",
            "noNumber": "Inget nummer hittades",
            "noMail": "Ingen E-post hittades",
            "copyLink": "Kopiera länk",
            "copied": "Kopierat till urklipp",
            "sendMeeting": "Skicka möte",
            "videoMeeting": "Videomöte",
            "integrationNotActive": "Integrationen är inte aktiv"
        }
    }
}
</i18n>
