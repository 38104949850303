<template>
    <section class="case-comments-tabs-outer">
        <!-- title="Stäng?" -->

        <section class="case-comments-tabs">
            <section class="pagination">
                <v-tooltip>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            :disabled="pagination.pagesToLeft < 1"
                            v-bind="attrs"
                            icon
                            @click="nextToLeft()"
                            v-on="on"
                        >
                            <v-icon v-show="activeUserCases.length >= 6" size="18">mdi-chevron-left</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>
            </section>
            <draggable
                v-if="show"
                v-model="shownCases"
                class="case-comments-tabs-container"
                @start="dragging(true, shownCases)"
                @end="dragging(false, shownCases)"
            >
                <article
                    v-for="(cases, chatIndex) in shownCases"
                    id="vCard"
                    :key="chatIndex"
                    height="40px"
                    :style="articleStyle(cases)"
                    class="case-comments-tabs"
                    :class="
                        isSameCase(cases.CaseID) ? 'case-comments-tabs-article-primary' : 'case-comments-tabs-article'
                    "
                    :ripple="false"
                    elevation="0"
                    @click="goToCase({ caseId: cases.CaseID })"
                >
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-badge
                                :value="cases.UnreadMessages > 0 && !isSameCase(cases.CaseID)"
                                left
                                offset-x="1"
                                offset-y="5"
                                color="color1"
                                :content="cases.UnreadMessages"
                                overlap
                                class="case-comments-tab"
                            >
                                <section class="case-comments-tab-item" v-bind="attrs" v-on="on">
                                    <section>
                                        <v-avatar color="gray3" size="24">
                                            <v-icon size="14" color="gray2">
                                                {{ cases.TypeIcon }}
                                            </v-icon>
                                        </v-avatar>
                                    </section>
                                    <section class="tabs-text">
                                        <span
                                            class="text-caption text-truncate"
                                            :style="{
                                                color: isSameCase(cases.CaseID) ? 'white' : 'var(--v-gray2-base)',
                                            }"
                                        >
                                            <div class="text-truncate">
                                                {{ formatTopic(cases.Topic) }}
                                            </div>
                                        </span>
                                    </section>
                                    <section>
                                        <v-btn
                                            fab
                                            depressed
                                            height="18"
                                            width="18"
                                            :active="false"
                                            :style="{
                                                backgroundColor: isSameCase(cases.CaseID)
                                                    ? 'transparent'
                                                    : 'var(--v-gray3-base)',
                                            }"
                                            @click.stop.prevent="removeCase(cases)"
                                        >
                                            <v-icon
                                                size="14"
                                                :style="{
                                                    color: isSameCase(cases.CaseID) ? 'white' : 'var(--v-gray2-base)',
                                                }"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </section>
                                </section>
                            </v-badge>
                        </template>
                        <span>{{ cases.Topic }}</span>
                    </v-tooltip>
                </article>
            </draggable>
            <section class="pagination">
                <v-tooltip>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            icon
                            :disabled="pagination.pagesToRight < 1"
                            @click="nextToRight()"
                            v-on="on"
                        >
                            <v-icon v-show="activeUserCases.length >= 5" size="18">mdi-chevron-right</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>
            </section>
        </section>
    </section>
</template>
<script>
    import { mapGetters, mapState, mapActions } from 'vuex';

    import draggable from 'vuedraggable';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';
    import { channelTypes } from '../../../templates/cases.template';

    export default {
        components: {
            draggable,
        },
        data() {
            return {
                activeChats: [],
                shownCases: [],
                maxAmountOfTabs: 1,
                currentCaseDelete: {},
                show: true,

                itemWidth: 0,
                pagination: {
                    start: 0,
                    end: 5,
                    pagesToLeft: 0,
                    pagesToRight: 0,
                },
            };
        },
        sockets: {
            changeInActiveCases(newVal) {
                const { action } = newVal;

                if (action === 'addOrUpdate' && this.activeUserCases.length > 5) {
                    this.pagination.pagesToRight++;
                }
            },
        },
        computed: {
            activeUserCases: {
                get() {
                    return this.$store.state.Cases.activeUserCases;
                },
                set(val) {
                    this.$store.dispatch('Cases/setActiveUserCases', val);
                },
            },

            ...mapGetters({
                cases: 'Cases/getCases',
            }),

            ...mapState({
                channelConfig: (state) => state.System.userSettings.system.channels,
            }),

            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value');
            },

            selectedCase() {
                return this.$store.state.Cases.selectedCase;
            },

            loggedInUser() {
                return this.$store.state.Auth.userObject;
            },

            getCaseSLA() {
                return this.$store.state.Cases.selectedCase.sla;
            },
        },
        watch: {
            activeUserCases: {
                deep: true,
                immediate: true,
                handler() {
                    this.shownCases = this.activeUserCases.slice(this.pagination.start, this.pagination.end);
                },
            },
        },
        async mounted() {
            await this.getActiveCasesByUser();

            this.pagination.pagesToRight = this.activeUserCases.length - this.shownCases.length;

            const elem = document.querySelector('#vCard');
            if (elem === null || elem === undefined) {
                return;
            }
            const x = elem.offsetWidth;
            this.itemWidth = x;
        },

        methods: {
            ...mapActions({
                goToCase: 'Cases/goToCase',
                getActiveCasesByUser: 'Cases/getActiveCasesByUser',
            }),

            caseTypeIcon(casea) {
                if (casea.internalType === undefined) {
                    return '';
                }
                if (casea.internalType === 'email' && casea.internalCreated === 2) {
                    return 'mdi-email';
                }
                const obj = this.filteredChannelTypes.find((t) => t.value === casea.internalType).icon;
                if (obj === undefined) {
                    return 'mdi-help-circle';
                }
                return obj;
            },

            articleStyle(item) {
                return {
                    color: this.isSameCase(item.CaseID) ? 'white' : 'var(--v-gray2-base)',
                    backgroundColor: this.isSameCase(item.CaseID) ? 'var(--v-primary-base)' : 'white',
                };
            },

            dragging(bool, val) {
                if (!bool) {
                    this.show = false;
                    this.handleChange(val);
                    this.$nextTick(() => {
                        this.show = true;
                    });
                }
            },

            handleChange(activeUserCases) {
                this.$store.dispatch('Cases/editActiveUserCases', {
                    activeUserCases,
                    pagesToLeft: this.pagination.pagesToLeft,
                    pagesToRight: this.pagination.pagesToRight,
                });
            },

            getItemWidth() {
                const elem = document.querySelector('#vCard');
                if (elem == null || elem === undefined) {
                    return 0;
                }
                const x = elem.offsetWidth;
                this.itemWidth = x;
                return this.itemWidth;
            },

            async removeCase(selectedCase) {
                const { confirmed } = await this.$global.dialogs.showConfirmationDialog({
                    title: this.$t('caseCommentsTabs.closeCase'),
                    confirmText: this.$t('global.btn.confirm'),
                    declineText: this.$t('global.btn.cancel'),
                });

                if (!confirmed) return;

                const { CaseID: caseId } = selectedCase;

                const payload = {
                    caseId,
                    changed: {
                        status: 'Closed',
                    },
                };

                this.$store.dispatch('Cases/updateCase', { payload });

                this.$store.dispatch('Cases/closeCaseTab', caseId);

                if (this.pagination.pagesToRight > 0 && this.shownCases.length === this.pagination.end) {
                    this.pagination.pagesToRight--;
                } else if (this.pagination.pagesToLeft > 0 && this.shownCases.length === this.pagination.end) {
                    this.pagination.pagesToLeft--;
                    this.pagination.start--;
                    this.pagination.end--;
                }
            },

            isSameCase(caseId) {
                if (this.selectedCase?.case?.caseId === undefined) {
                    return false;
                }
                return this.selectedCase.case.caseId === caseId;
            },

            nextToRight() {
                this.pagination.start++;
                this.pagination.end++;
                this.pagination.pagesToLeft++;
                this.pagination.pagesToRight--;
                this.shownCases = this.activeUserCases.slice(this.pagination.start, this.pagination.end);
            },

            nextToLeft() {
                this.pagination.start--;
                this.pagination.end--;
                this.pagination.pagesToRight++;
                this.pagination.pagesToLeft--;
                this.shownCases = this.activeUserCases.slice(this.pagination.start, this.pagination.end);
            },

            formatTopic(topic) {
                if (!topic) {
                    return '';
                }
                return topic.length > 15 ? `${topic.slice(0, 15)}...` : topic;
            },
        },
    };
</script>
<style scoped>
    .case-comments-tabs-outer {
        display: flex;
        flex: 1;
        width: 100%;
    }
    .chatButton:last-child {
        margin-right: 0px !important;
    }

    .chatButton {
        transition: all 0.2s ease;
        margin-right: 8px !important;
    }

    .case-comments-tabs-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 8px;
        flex: 1;
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0px 8px;
    }

    .tabs-text {
        /* truncate*/
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 0 1;
    }

    .case-comments-tabs {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;
    }

    .case-comments-tab-item {
        display: grid;
        grid-template-columns: auto 1fr auto;
        width: 100%;
        gap: 8px;
    }

    .case-comments-tab-item > * {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .case-comments-tabs-article {
        width: 100%;
        padding: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .case-comments-tabs-article-primary {
        width: 100%;
        padding: 8px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .case-comments-tabs-article:hover {
        background-color: var(--v-gray3-base) !important;
    }

    .case-comments-tabs-article-primary:hover {
        background-color: var(--v-primary-base) !important;
    }

    .case-comments-tab {
        width: 100%;
    }
</style>
<i18n lang="json">
{
    "en": {
        "caseCommentsTabs": {
            "closeCase": "Do You want to close the case?"
        }
    },
    "sv": {
        "caseCommentsTabs": {
            "closeCase": "Vill du stänga ärendet?"
        }
    }
}
</i18n>
