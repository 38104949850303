<template>
    <section :key="clientId" class="clients-family-container" :style="containerHeightStyle">
        <infinite-list
            ref="list"
            :headers="[]"
            :fetchItems="getClientsFamily"
            :items="clientsFamily"
            :limit="itemsToShow + 1"
            :fetchProps="{ id: clientId }"
            :reverse="true"
            :fixed="true"
            :emptyHeader="$t('clientFamily.empty')"
            :emptyMessage="$t('clientFamily.emptyMessage')"
            emptyIcon="mdi-account-group"
        >
            <template #item="{ item }">
                <ClientFamilyItem :item="item" @click="handleItemClicked(item)" />
            </template>
        </infinite-list>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ClientFamilyItem from './ClientFamilyItem.vue';

    export default {
        name: 'ClientFamily',

        components: {
            ClientFamilyItem,
        },

        props: {
            clientId: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                itemHeight: 50,
                itemsToShow: 4,
            };
        },

        computed: {
            ...mapState('Client', ['clientsFamily', 'currentSelectedClient']),

            containerHeight() {
                return `${Math.min(this.clientsFamily.length * this.itemHeight, this.itemHeight * this.itemsToShow)}px`;
            },

            containerHeightStyle() {
                return {
                    minHeight: this.containerHeight,
                    maxHeight: this.containerHeight,
                };
            },
        },

        watch: {
            clientId() {
                for (const client of this.clientsFamily) {
                    this.unsubscribeToClient(client.id);
                }
            },
        },

        mounted() {
            this.getTimeline({ id: this.clientId, page: 1, limit: 10 });
        },

        beforeDestroy() {
            for (const client of this.clientsFamily) {
                this.unsubscribeToClient(client.id);
            }
        },

        methods: {
            ...mapActions('Client', ['getClientsFamily', 'getClient', 'getTimeline', 'unsubscribeToClient']),

            handleItemClicked(item) {
                this.getClient(item.id);
                this.getTimeline({ id: item.id, page: 1, limit: 50 });
                this.$refs.list.scrollToItemId(item.id);
            },
        },
    };
</script>

<style scoped>
    .clients-family-container {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        overflow: hidden;
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientFamily": {
            "empty": "No Family",
            "emptyMessage": "This client has no family"
        }
    },
    "sv": {
        "clientFamily": {
            "empty": "Ingan familj",
            "emptyMessage": "Denna klient har ingen familj"
        }
    }
}
</i18n>
