import users from '@/api/users/users';
import * as Sentry from '@sentry/vue';
import { format, addSeconds } from 'date-fns';
import i18n from '../../i18n';
import { wrapupActions } from '../../enums/users.enums';

// State object
const state = {
    allUsers: [],
    currentUserData: {},
    currentDialogs: [],
    divertSettings: [],
    userActionModuleOpen: false,
    userActionModuleModifier: {
        ctrl: false,
        shift: false,
        alt: false,
    },
    userNumbers: [],
    userNotification: {},
    userPreferences: {
        showSystemNotifications: false,
        autocompleteInComment: false,
    },
};
// Getter functions
const getters = {
    currentUserData(state) {
        return state.currentUserData;
    },
};

// Actions
const actions = {
    getUserData(_, userId) {
        return new Promise((resolve, reject) => {
            users
                .getUsers([userId])
                .then((res) => {
                    resolve(res.data.at(0));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getUserProfileImage(_, { userId, resolution = '128' }) {
        if (!userId || Number.isNaN(Number(userId))) {
            return require('@/assets/General/no_user.png');
        }
        try {
            const res = await users.getUserProfileImageByUserId(userId, resolution);
            if (!res.data) {
                return require('@/assets/General/no_user.png');
            }
            return `data:image/png;base64,${res.data}`;
        } catch (_error) {
            return require('@/assets/General/no_user.png');
        }
    },

    setUserProfileImage(_, { userId, image }) {
        return new Promise((resolve, reject) => {
            users
                .setUserProfileImage(userId, image)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('usersStore.wrong'), {
                        icon: 'cancel',
                        type: 'error',
                    });

                    reject(error);
                });
        });
    },

    getUserNotifications({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            users
                .getUserNotification(rootState.Auth.userObject?.userId)
                .then((res) => {
                    commit('SET_USER_NOTIFICATION', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setAlert({ commit }, payload) {
        commit('SET_USER_NOTIFICATION', payload);
    },
    updateCurrentCommunications({ commit }, payload) {
        commit('SET_CURRENT_DIALOGS', payload.communications);
        commit('SET_DIVERT_SETTINGS', payload.settings);
    },
    getCurrentCommunications({ commit }, payload) {
        return new Promise((resolve, reject) => {
            users
                .getCurrentCommunications(payload)
                .then((res) => {
                    commit('SET_CURRENT_DIALOGS', res.data.communications);
                    commit('SET_DIVERT_SETTINGS', res.data.settings);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    allUsers({ commit }) {
        return new Promise((resolve, reject) => {
            users
                .allUsers()
                .then((res) => {
                    commit('SET_ALL_USERS', res.data);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getUsers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            users
                .getUsers(payload)
                .then((res) => {
                    commit('SET_USER_DATA', res.data[0] || {}); // this could prove problematic - Teo
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getSpecificUserInfo(_, payload) {
        return new Promise((resolve, reject) => {
            users
                .getSpecificUserInfo(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateUserPresence(_, payload) {
        return new Promise((resolve, reject) => {
            users
                .updateUserPresence(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getIntegrationLink(_, payload) {
        return new Promise((resolve, reject) => {
            users
                .getIntegrationLink(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setIntegrationLink({ commit }, payload) {
        return new Promise((resolve, reject) => {
            users
                .setIntegrationLink(payload)
                .then((res) => {
                    commit('SET_USER_INTEGRATION_LINK', payload);
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    exitQuarantine({ dispatch }, userId) {
        try {
            const fields = { quarantine: 0 };
            return dispatch('updateUserFields', { userId, fields });
        } catch (_error) {
            return null;
        }
    },

    async updateUserFields(_, { userId, fields }) {
        try {
            const { data } = await users.updateUserFields({ userId, fields });
            return data;
        } catch (_error) {
            return null;
        }
    },

    togglePasswordChange(_, payload) {
        return new Promise((resolve, reject) => {
            users
                .togglePasswordChange(payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    },
    /**
     * Calculates the capacity left for the current user based on the channel type.
     * @param {Object} state - The Vuex state object.
     * @param {string} channelType - The type of channel to calculate the capacity for.
     * @returns {number} - The capacity left for the current user.
     */
    getCapacityLeftForCurrentUser({ state }, channelType) {
        const { currentDialogs } = state;
        const dialogsWithChannel = currentDialogs.filter((dialog) => dialog.channelType === channelType);
        const capacity = dialogsWithChannel.reduce((acc, dialog) => acc + dialog.capacity, 0);
        return capacity;
    },

    setUserActionModuleModifiers({ commit }, payload) {
        let name = '';
        // eslint-disable-next-line default-case
        switch (payload.key) {
            case 'Control': {
                name = 'ctrl';

                break;
            }
            case 'Alt': {
                name = 'alt';

                break;
            }
            case 'Shift': {
                name = 'shift';

                break;
            }
            // No default
        }
        commit('SET_USER_ACTION_MODULE_MODIFIER', { name, value: payload.value });
    },
    resetUserActionModuleModifiers({ commit }) {
        commit('RESET_USER_ACTION_MODULE_MODIFIER');
    },
    clearUserData({ commit }) {
        commit('SET_USER_DATA', []);
    },

    async getUsersAndRoles(_, payload) {
        try {
            const res = await users.getUsersAndRoles(payload);
            return res.data;
        } catch (error) {
            console.error('Failed to get users -->', error, Date.now());
            Sentry.captureException(error);
            return { data: [], hasMore: false };
        }
    },

    async getUserCount() {
        try {
            const res = await users.getUserCount();
            return res.data;
        } catch (error) {
            console.error('Failed to get users count -->', error, Date.now());
            Sentry.captureException(error);
            throw error;
        }
    },

    extendWrapupTime(_, { lastHangup, extendedWrapup, userId }) {
        const startDate = lastHangup > new Date() ? new Date(lastHangup) : new Date();
        const newLastHangup = format(addSeconds(startDate, extendedWrapup), 'yyyy-MM-dd HH:mm:ss');

        return new Promise((resolve, reject) => {
            users
                .setLastHangup(wrapupActions.EXTEND, userId, newLastHangup)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    resetLastHangup(_, userId) {
        return new Promise((resolve, reject) => {
            users
                .setLastHangup(wrapupActions.RESET, userId)
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getUser(_, userId) {
        try {
            const { data } = await users.getUser(userId);
            return data;
        } catch (error) {
            console.error('Failed to get user -->', error, Date.now());
            Sentry.captureException(error);
            throw error;
        }
    },
    async getUserSettings({ commit }, userId) {
        try {
            const { data } = await users.getUserSettings(userId);
            commit('SET_USER_PREFERENCES', data);
            return data;
        } catch (error) {
            console.error('Failed to get user settings -->', error, Date.now());
            Sentry.captureException(error);
            throw error;
        }
    },
    async setUserSettings(_, { userId, settings }) {
        try {
            const { data } = await users.setUserSettings(userId, settings);
            return data;
        } catch (error) {
            console.error('Failed to set users settings -->', error, Date.now());
            Sentry.captureException(error);
            throw error;
        }
    },

    toggleActionModule({ commit }) {
        const data = !state.userActionModuleOpen;
        commit('SET_USER_ACTION_MODULE_OPEN', data);
    },
};

// Mutations
const mutations = {
    SET_USER_ACTION_MODULE_MODIFIER(state, data) {
        state.userActionModuleModifier[data.name] = data.value;
    },
    RESET_USER_ACTION_MODULE_MODIFIER(state) {
        state.userActionModuleModifier = {
            ctrl: false,
            alt: false,
            shift: false,
        };
    },
    SET_ALL_USERS(state, data) {
        state.allUsers = data;
    },
    SET_USER_DATA(state, data) {
        state.currentUserData = data;
    },
    SET_USER_INTEGRATION_LINK(state, data) {
        state.currentUserData.SoapIntegration = data.value;
    },
    SET_USER_NOTIFICATION(state, data) {
        state.userNotification = data;
    },
    SET_CURRENT_DIALOGS(state, data) {
        state.currentDialogs = data;
    },
    SET_DIVERT_SETTINGS(state, data) {
        state.divertSettings = data;
    },
    SET_USER_ACTION_MODULE_OPEN(state, data) {
        state.userActionModuleOpen = data;
    },
    SET_USER_PREFERENCES(state, data) {
        state.userPreferences = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
