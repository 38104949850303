<template>
    <article
        class="module-item module-item-dialog"
        :class="item.unreadMessages ? 'active' : 'deactive'"
        @click="navigateCase(item.caseId)"
    >
        <div v-if="selectedCase?.case?.caseId === item.caseId" class="activity-line"></div>

        <div class="icon-container">
            <i class="mdi" :class="dialogIcon"></i>
        </div>
        <div>
            <span>{{ item.topic }}</span>
        </div>
        <div class="duration-container">
            <span>{{ dialogDuration }}</span>
        </div>
        <div>
            <QueueChip :item="item" />
        </div>
    </article>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import QueueChip from '@/components/Global/UserActionModule/Modules/Items/QueueChip.vue';

    export default {
        name: 'ModuleDialog',

        components: {
            QueueChip,
        },

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                currentDuration: 0,
                interval: null,
            };
        },

        computed: {
            ...mapState('Cases', ['selectedCase']),

            dialogIcon() {
                switch (this.item.channelType) {
                    case 'email': {
                        return 'mdi-email';
                    }
                    case 'chat': {
                        return 'mdi-message';
                    }
                    case 'call': {
                        return 'mdi-phone';
                    }
                    default: {
                        return 'mdi-account';
                    }
                }
            },

            dialogDuration() {
                const startTime = new Date(this.item.answeredTime);
                const endTime = new Date(Date.now() + this.currentDuration);
                const timeDifference = endTime - startTime;

                const seconds = Math.floor((timeDifference / 1000) % 60);
                const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
                const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

                if (days > 0) {
                    return `${days} d ${hours} h ${minutes} m`;
                }
                if (hours > 0) {
                    return `${hours} h ${minutes} m`;
                }
                if (minutes > 0) {
                    return `${minutes} m ${seconds} s`;
                }
                return `${seconds} s`;
            },
        },

        created() {
            this.interval = setInterval(() => {
                this.currentDuration += 1000;
            }, 1000);
        },

        beforeDestroy() {
            clearInterval(this.interval);
        },

        methods: {
            ...mapActions('Cases', ['goToCase']),
            ...mapActions('Users', ['toggleActionModule']),

            navigateCase(caseId) {
                this.goToCase({ caseId });
                this.toggleActionModule();
            },
        },
    };
</script>

<style scoped>
    .activity-line {
        background: var(--v-primary-base);
        height: 100%;
        width: 4px;
        position: absolute;
        left: 0;
    }

    .module-item {
        background: white;
        border-bottom: 1px solid #e0e0e0;
        display: grid;
        grid-template-columns: auto 1fr auto;
        min-height: 48px;
        transition: all 0.3s;
        font-size: 12px;
        user-select: none;
        gap: 16px;
        padding: 0px 16px;
        position: relative;
        cursor: pointer;
    }

    .module-item > * {
        align-items: center;
        display: flex;
        overflow: hidden;
    }

    .module-item > div > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .module-item :last-child {
        justify-content: flex-end;
    }

    .module-item:hover {
        background: #f5f5f5;
    }

    .module-item:active {
        background: #e0e0e0;
    }

    i {
        color: var(--v-primary-base);
    }

    .icon-container {
        padding: 0px;
    }

    .active {
    }

    .deactive {
    }

    .module-item-dialog {
        grid-template-columns: auto 2fr 1fr auto;
    }

    .duration-container {
        font-size: 10px;
    }
</style>
