import { render, staticRenderFns } from "./List.vue?vue&type=template&id=274c494a&scoped=true"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"
import style0 from "./List.vue?vue&type=style&index=0&id=274c494a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.38_css-loader@6.11.0_webpack@5.94.0__ejs@3.1.10_hand_dktyoznlne5piz2et7ozwauvzq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274c494a",
  null
  
)

export default component.exports