module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clientChildren": {
          "childs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No contacts"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This client has no contacts"])}
        }
      },
      "sv": {
        "clientChildren": {
          "childs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakter"])},
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga kontakter"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna klient har inga kontakter"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"clientChildren":{"childs":"Contacts","empty":"No contacts","emptyMessage":"This client has no contacts"}},"sv":{"clientChildren":{"childs":"Kontakter","empty":"Inga kontakter","emptyMessage":"Denna klient har inga kontakter"}}}')
  delete Component.options._Ctor
  
}
