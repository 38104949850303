module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clientFamily": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Family"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This client has no family"])}
        }
      },
      "sv": {
        "clientFamily": {
          "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingan familj"])},
          "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna klient har ingen familj"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"clientFamily":{"empty":"No Family","emptyMessage":"This client has no family"}},"sv":{"clientFamily":{"empty":"Ingan familj","emptyMessage":"Denna klient har ingen familj"}}}')
  delete Component.options._Ctor
  
}
