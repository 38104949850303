<template>
    <section
        v-if="
            ![states.ERROR, states.NOT_FOUND, states.INIT].includes(clientState) &&
            !clientIsNull &&
            clientCardState === clientStates.OPEN
        "
        class="client-container-card"
        cy-data="clientCard"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <Integrations :key="'i' + clientCard.data.clientId" />
        <ClientContactBar />
        <ContactInfo />

        <section class="client-scroll-header">
            <div :cy-value="stateView">
                {{ stateViewHeader }}
            </div>
            <div class="view-selector" cy-data="clientCardViewSelector">
                <ClientCardActionButton state="default" :tooltip-label="$t('actionButtonLabels.cases')" />
                <ClientCardActionButton state="fields" :tooltip-label="$t('actionButtonLabels.clientFields')" />

                <IntegrationIframeButtons />
                <IntegrationOrderButtons />
            </div>
        </section>

        <section :key="clientId" class="client-scroll-container">
            <component
                :is="currentTab"
                v-show="loadingState !== 'loading'"
                @loading-state="(v) => (loadingState = v)"
            />
            <v-progress-circular
                v-if="loadingState === 'loading'"
                size="50"
                indeterminate
                color="primary"
                class="view-loader"
            />
        </section>

        <section v-show="hover" class="client-card-buttons">
            <tooltip :content="$t('clientCard.hide')">
                <v-btn
                    fab
                    elevation="0"
                    height="28"
                    width="28"
                    class="client-card-round-button"
                    cy-data="hideClientCard"
                    @click="toggleClientCard"
                >
                    <v-icon size="20" color="var(--v-gray4-base)">mdi-chevron-left</v-icon>
                </v-btn>
            </tooltip>

            <tooltip :content="$t('clientCard.connect')">
                <v-btn
                    fab
                    elevation="0"
                    height="28"
                    width="28"
                    class="client-card-round-button"
                    @click="connectClientToCase"
                >
                    <v-icon size="18" color="var(--v-gray4-base)">mdi-account-plus</v-icon>
                </v-btn>
            </tooltip>
        </section>
    </section>

    <section
        v-else-if="clientState === states.ERROR || clientState === states.NOT_FOUND"
        class="client-container-card client-container-card-error softShadow"
    >
        <div v-if="clientState === states.ERROR" class="client-card-error-container">
            <v-icon>mdi-alert-circle</v-icon>
            <span class="client-card-error-message">{{ $t('clientCard.error') }}</span>
        </div>

        <div v-if="clientState === states.NOT_FOUND" class="client-card-error-container">
            <v-icon>mdi-alert-circle</v-icon>
            <span class="client-card-error-message">{{ $t('clientCard.notFound') }}</span>
        </div>
    </section>

    <section
        v-else-if="clientCardState === clientStates.CLOSED"
        class="client-container-card-closed"
        cy-data="showClientCard"
    >
        <div class="client-card-closed-container" @click="toggleClientCard">
            <v-icon color="var(--v-gray2-base)" size="20">mdi-chevron-right</v-icon>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import TimelineView from '@/components/Cases/Timeline.vue';
    import SignatureView from '@/components/Cases/Sidebars/SignatureView.vue';
    import Integrations from '@/components/Cases/Integrations/Integrations.vue';
    import TemplateView from '@/components/Cases/Sidebars/TemplateView/TemplateView.vue';
    import ContactInfo from '@/components/Cases/ContactInfo.vue';
    import ClientContactBar from '@/components/Cases/ClientContactBar.vue';
    import ClientCardActionButton from '@/components/Cases/ClientCard/ClientCardActionButton.vue';
    import IntegrationIframe from '@/components/Cases/Integrations/Iframes/IntegrationIframe.vue';
    import IntegrationIframeButtons from '@/components/Cases/Integrations/Iframes/IntegrationIframesButton.vue';
    import Recordings from '@/components/Cases/Recordings/Recordings.vue';
    import ClientFields from '@/components/Cases/ClientFields.vue';
    import IntegrationOrders from '@/components/Cases/Integrations/Orders/IntegrationOrders.vue';
    import IntegrationOrderButtons from '@/components/Cases/Integrations/Orders/IntegrationOrderButtons.vue';

    import { states } from '@/enums/cases.enums';

    const { states: clientStates } = require('@/enums/client.enums');

    export default {
        components: {
            TimelineView,
            TemplateView,
            SignatureView,
            Integrations,
            ContactInfo,
            ClientContactBar,
            Recordings,
            IntegrationIframe,
            IntegrationIframeButtons,
            ClientCardActionButton,
            IntegrationOrders,
            IntegrationOrderButtons,
        },

        data() {
            return {
                states,
                loadingState: null,
                clientStates,
                hover: false,
            };
        },

        computed: {
            ...mapState({
                clientCard: (state) => state.Cases.clientNowOnCard,
                readClientCard: (state) => state.Layout.readClientCard,
                readStateClientCard: (state) => state.Layout.readStateClientCard,
                clientState: (state) => state.Cases.clientState,
                stateView: (state) => state.Cases.sideBarView,
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                settings: (state) => state.System.userSettings,
                clientCardState: (state) => state.Client.clientCardState,
                userSettings: (state) => state.System.userSettings,
            }),

            ...mapGetters({
                isReadOnly: 'Cases/isReadOnly',
            }),

            shouldShowIframeButton() {
                return this.settings?.clientCard?.iframe?.active;
            },

            clientId() {
                return this.currentSelectedClient?.clientId;
            },

            currentTab() {
                const tabMap = {
                    recordings: Recordings,
                    templates: TemplateView,
                    signature: SignatureView,
                    fields: ClientFields,
                    iframe: IntegrationIframe,
                    // ! TODO: Make this more generic to enable future integrations also only show if function is active
                    orders: IntegrationOrders,
                };

                return tabMap[this.stateView] || TimelineView;
            },

            clientIsNull() {
                return this.clientCard === null;
            },

            stateViewHeader() {
                return this.$t('sidebarExpander.' + this.stateView);
            },
        },

        methods: {
            ...mapActions({
                setClientContactInfo: 'Cases/setClientContactInfo',
                toggleClientCard: 'Client/toggleClientCard',
                handleAction: 'Client/handleAction',
            }),

            connectClientToCase() {
                this.handleAction({ value: 'connectClientToCase' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .view-selector {
        display: flex;
        gap: 8px;
    }
    .client-scroll-header {
        min-height: 48px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: var(--v-gray2-base);
        padding: 0px 16px;
    }

    .client-scroll-container {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;

        border-radius: 0 0 16px 16px;
        overflow-y: auto;
        height: 100%;
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.05);
        position: relative;
        .view-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .client-container-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        position: relative;
    }

    .client-container-card-error {
        background-color: white;
        border-radius: 8px;
        padding-top: 64px;
        align-items: center;
    }

    .client-card-error-message {
        text-align: center;
        color: var(--v-gray2-base);
    }

    .client-card-error-container {
        max-width: 300px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .client-card-closed-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        color: var(--v-gray2-base);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px 0px 0px 16px;
        width: 150%;
        padding-left: 16px;
        transition: background-color 0.2s;
    }

    .client-card-closed-container:hover {
        background-color: var(--v-gray3-base);
    }

    .client-container-card-closed {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        height: 100%;
        background-color: white;
        border-radius: 16px 0px 0px 16px;
        box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.05);
    }

    .client-container-card-closed:hover {
        cursor: pointer;
    }

    .client-card-buttons {
        position: absolute;
        top: 54px;
        right: -12px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .client-card-round-button {
        background-color: white !important;
        border: 1px solid #ccc;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
</style>

<i18n lang="json">
{
    "en": {
        "clientCard": {
            "error": "Could not load client card, please try to reload the page",
            "notFound": "Client not found",
            "hide": "Hide client card",
            "connect": "Connect client to case"
        },

        "sidebarExpander": {
            "templates": "Templates",
            "webRecording": "Web recording",
            "mobPreview": "Mobile preview",
            "pdfExport": "PDF export",
            "signature": "Signature",
            "default": "Other cases",
            "fields": "Fields",
            "timeline": "Timeline",
            "iframe": "External system",
            "orders": "Order list"
        },
        "actionButtonLabels": {
            "cases": "Cases",
            "clientFields": "Client fields"
        }
    },

    "sv": {
        "clientCard": {
            "error": "Kunde inte ladda klientkortet, vänligen försök att ladda om sidan",
            "notFound": "Klienten kunde inte hittas",
            "hide": "Dölj klientkort",
            "connect": "Koppla klient till ärende"
        },

        "sidebarExpander": {
            "templates": "Mallar",
            "webRecording": "Webinspelning",
            "mobPreview": "Mobil förhandsvisning",
            "pdfExport": "PDF Export",
            "signature": "Signatur",
            "default": "Andra ärenden",
            "fields": "Fält",
            "timeline": "Tidslinje",
            "iframe": "Extern system",
            "orders": "Orderlista"
        },
        "actionButtonLabels": {
            "cases": "Ärenden",
            "clientFields": "Klientfält"
        }
    }
}
</i18n>
