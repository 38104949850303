<template>
    <article :style="borderStyle" class="activity-line">
        <v-tooltip right :disabled="!isBusy">
            <template #activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    :class="{
                        selectedRowBorder: item.caseId === selectedCaseId,
                        selectedRowBorderBusy: isBusy && item.caseId !== selectedCaseId,
                    }"
                    :style="{
                        maxWidth: small ? '4px' : '6px',
                    }"
                    class="border pa-0 mx-0"
                    v-on="on"
                ></div>
            </template>
            <span>{{ busyText }}</span>
        </v-tooltip>
    </article>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: {
            item: {
                type: Object,
                default: () => ({}),
            },
            small: {
                type: Boolean,
                default: false,
            },
            border: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            ...mapState({
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                busyCaseList: (state) => state.Cases.busyCaseList,
            }),

            busyText() {
                return this.nameOfTheActiveUser + ' ' + this.$t('workingCase.recipient');
            },
            nameOfTheActiveUser() {
                const working = this.busyCaseList.find((e) => e.id === this.item.caseId && e.activeUser);
                return working ? working.userName : null;
            },

            isBusy() {
                return this.busyCaseList.some((e) => e.id === this.item.caseId && e.activeUser);
            },

            borderStyle() {
                return {
                    borderTopLeftRadius: '3px',
                    borderBottomLeftRadius: '3px',
                };
            },
        },
    };
</script>

<style scoped>
    .border {
        width: 100%;
        min-height: 100%;
        height: 100%;
        padding: 0px;
        display: block;
        margin-right: 6px !important;
        background-color: transparent;
    }

    .selectedRowBorder {
        background-color: var(--v-primary-base) !important;
    }

    .selectedRowBorderBusy {
        background-color: var(--v-color1-base) !important;
    }

    .activity-line {
        position: absolute;
        left: 0;
        z-index: 2;
        height: 100%;
    }
</style>
<i18n lang="json">
{
    "en": {
        "workingCase": {
            "recipient": "is currently working on this case."
        }
    },
    "sv": {
        "workingCase": {
            "recipient": "jobbar i detta ärende just nu."
        }
    }
}
</i18n>
